.publication {
    position: relative;
    margin-bottom: 26px;

    @media (max-width: 767px) {
        margin-left: 20px;
    }

    .icon {
        position: absolute;
        left: -27px;
        top: -2px;
    }

    .publi-image {
        float: right;
        max-width: 20%;
        img {
            max-width: 100%;
            max-height: 150px;
            margin-left: 20px;
        }
    }

    .publi-content {}

    // normalize to smaller spacing
    .publi-title, .publi-authors, .publi-in, .publi-urls {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .publi-in {
        font-style: italic;
    }


    &.tidy {
        font-size: 14px;
        margin-bottom: 14px;

        .icon {
            left: -22px;
        }

        .publi-image {
            max-width: 15%;
            img {
                max-height: 90px;
            }
        }

        .publi-content {}

        .publi-title {
            font-size: 18px;
            margin-bottom: 2px;
        }

        .publi-authors {
            font-size: 15px;
            margin-bottom: 3px;
        }

        .btn-xs {
            padding-left: 9px;
            padding-right: 9px;
        }
    }

    &.publi-highlight {
        .publi-title {
            font-weight: 700;
            text-shadow: 0 1px 0.7px rgba(0, 0, 0, .3);
        }
    }
}

.layout-publications {

    // years title
    h2 {
        color: $color-1-dark;
        margin: 35px 0 20px;
        text-align: center;
    }

    .publication {
        .icon {
            color: $color-1;
        }
        .publi-title {
            color: $color-1;
        }

        .color-emph-light {
            color: $color-1-light;
        }

        .small {
            color: $gray-light;
        }
    }

    &.publi-highlight {
        .publi-title {
            font-weight: 700;
            text-shadow: 0 1px 0.7px rgba($color-1-dark,.3);
        }
    }
}

.layout-projects {
    .project {
        margin: 0 0 30px;

        .proj-image {
            width: 20%;
            float: left;
        }

        .proj-image img {
            max-width: 100%;
            max-height: 200px;
        }

        .proj-content {
            margin-left: 22%;
            width: 78%;
            @media (min-width: 992px) {
                width: 66%;
                margin-right: 12%;
            }

        }

        h2 {
            color: $color-2;
            margin: 0px 0 7px;
        }

        .proj-date {
            color: $color-2-light;
            font-size: 110%;
        }

    }
}
