/*=================================
*======= TIMELINE =================
*===============================*/

/* CONTAINER */	
.cd-container::after { content: ''; display: table; clear: both; } /* clearfix */
#cd-timeline { position: relative; padding: 30px 0; }
#cd-timeline::before { content: ''; position: absolute; top: 0; left: 18px; height: 100%; width: 4px; background: #B7C4CC; } /* line */

.cd-timeline-dot { position: relative; height: 8px; margin-top: 8px; }
.cd-timeline-dot::before { content: ''; position: absolute; top: 0; left: 18px; height: 8px; width: 4px; background: #B7C4CC; } /* line */

@media only screen and (min-width: 992px) {
	#cd-timeline.cd-offset { margin-left: 175px; min-height: 500px; }
	#cd-timeline.cd-offset::before { left: 150px; }
}

@media only screen and (min-width: 1200px) {
	#cd-timeline.cd-center::before, .cd-timeline-dot::before { left: 50%; margin-left: -2px; }
}

/* CONTROLS */

#timeline-controls li span, #timeline-controls .show-all { cursor: pointer; }
#timeline-controls ul { list-style-type: none; padding: 5px 0 10px; margin: 0; }
#timeline-controls ul li { float: left; margin-right: 18px; }
@media only screen and (min-width: 992px) {
	/*#timeline-controls { position: fixed; top: 0px; width: 175px; padding-top: 80px; height: 100%; overflow-y: overlay; }*/
	#timeline-controls ul li { float: none; margin: 0; }
	#timeline-controls { position: absolute; width: 175px; }
}

/* BLOCKS */
.cd-timeline-block { position: relative; margin: 0 0 2em; }
.cd-timeline-block:after { content: ""; display: table; clear: both; }
.cd-timeline-block:last-child { margin-bottom: 0; }

.cd-offset .cd-timeline-block.cd-enter .cd-timeline-img { visibility: visible; -webkit-animation: cd-enter-img 1s; -moz-animation: cd-enter-img 1s; animation: cd-enter-img 1s; }
.cd-offset .cd-timeline-block.cd-enter .cd-timeline-content { visibility: visible; -webkit-animation: cd-enter-content 1s; -moz-animation: cd-enter-content 1s; animation: cd-enter-content 1s; }
.cd-offset .cd-timeline-block.cd-leave .cd-timeline-img { opacity: 0; -webkit-animation: cd-leave-img 0.4s; -moz-animation: cd-leave-img 0.4s; animation: cd-leave-img 0.4s; }
.cd-offset .cd-timeline-block.cd-leave .cd-timeline-content { opacity: 0; -webkit-animation: cd-leave-content 0.4s; -moz-animation: cd-leave-content 0.4s; animation: cd-leave-content 0.4s; }

@media only screen and (min-width: 1200px) {
	.cd-center .cd-timeline-block { margin: 0 0 4em; }
	.cd-center .cd-timeline-block:last-child { margin-bottom: 0; }
}

/* CIRCLES */
.cd-timeline-img { position: absolute; top: 0; left: 0; width: 40px; height: 40px; border-radius: 50%; box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.10); text-align: center; line-height: 43px; color: #FFF; font-size: 22px; behavior: url('/assets/js/PIE.htc'); }

@media only screen and (min-width: 992px) {
	.cd-offset .cd-timeline-img { left: 130px; }
}

@media only screen and (min-width: 1200px) {
	.cd-center .cd-timeline-img { width: 60px; height: 60px; left: 50%; margin-left: -30px; line-height: 64px; font-size: 28px;  /* Force Hardware Acceleration in WebKit -webkit-transform: translateZ(0); -webkit-backface-visibility: hidden; */ }
	.cd-center .cd-timeline-img.is-hidden { visibility: hidden; }
	.cd-center .cd-timeline-img.bounce-in { visibility: visible; -webkit-animation: cd-bounce-1 0.6s; -moz-animation: cd-bounce-1 0.6s; animation: cd-bounce-1 0.6s; }
}

/* CONTENT */

.cd-timeline-content { position: relative; margin-left: 60px; background: white; border-radius: 0.25em; padding: 15px; box-shadow: 0 4px 3px -1px rgba(0, 0, 0, 0.10); font-size: 0.9em; behavior: url('/assets/js/PIE.htc'); }
.cd-timeline-content:after { content: ""; display: table; clear: both; }

.cd-timeline-content p { margin: 0 0 6px; }
.cd-timeline-content ul { padding: 0 0 0 19px; }
.cd-timeline-content h3 { color: #303e49; margin: 0 0 8px; font-size: 1.4em; text-align: left; }
.cd-timeline-content p.subtitle { color: #303e49; font-size: 1em; margin: 0 0 8px; }

.cd-timeline-content .cd-date {
    font-size: 13px; opacity: .7; padding-bottom: 0.7em; font-weight: bold;
    em { font-weight: normal; font-style: normal; }
}
.cd-timeline-content .cd-date span, .cd-timeline-content .cd-date strong, .cd-timeline-content .cd-date em { padding-right: 10px; }

.cd-timeline-content .cd-tags span { font-size: 11px; background: #CCC; border-right: 3px solid #999; line-height: 16px; padding: 0px 4px; margin: 0 -18px 4px 0; float: right; clear: both; }
.cd-timeline-content .cd-tags img { margin: 5px -7px 5px 5px; float: right; clear: both; max-height: 50px; max-width: 70px; opacity: 0.5; -webkit-transition: opacity .3s ease-out; -moz-transition: opacity .3s ease-out; -o-transition: opacity .3s ease-out; transition: opacity .3s ease-out; }
.cd-timeline-content .cd-tags img.big { max-width: 90px; max-height: 90px; }
.cd-timeline-content .cd-tags img:hover { opacity: 1; -webkit-transition: opacity .3s ease-in; -moz-transition: opacity .3s ease-in; -o-transition: opacity .3s ease-in; transition: opacity .3s ease-in; }

.cd-timeline-content .cd-read-more { font-size: 13px; text-transform: none; background: #EAECED; behavior: url('/assets/js/PIE.htc'); }

.no-touch .cd-timeline-content .cd-read-more:hover { background-color: #bac4cb; }
.cd-timeline-content::before { content: ''; position: absolute; top: 16px; right: 100%; height: 0; width: 0; border: 7px solid transparent; border-right: 7px solid white; }

@media only screen and (min-width: 992px) {
	.cd-timeline-content .cd-tags span { font-size: 13px; line-height: inherit; padding: 0px 6px; }
	.cd-timeline-content .cd-tags img { margin: 5px -7px 5px 5px; max-height: 60px; max-width: 90px; }
	.cd-timeline-content .cd-tags img.big { max-width: 150px; max-height: 150px; }

	.cd-offset .cd-timeline-content { margin-left: 190px; }
	.cd-offset .cd-timeline-content .cd-date { position: absolute; width: 120px; left: -190px; top: 6px; font-size: inherit; text-align: right; padding: 8px 0 0; line-height: 1.2em; }
	.cd-offset .cd-timeline-content .cd-date span, .cd-offset .cd-timeline-content .cd-date strong, .cd-offset .cd-timeline-content .cd-date em { display: block; padding: 0; }
}

@media only screen and (min-width: 1200px) {
	.cd-center .cd-timeline-content { margin-left: 0; padding: 1.2em; width: 45%; }
	.cd-center .cd-timeline-content::before { top: 24px; left: 100%; border-color: transparent; border-left-color: white; }
	.cd-center .cd-timeline-content .cd-read-more { float: left; }
	.cd-center .cd-timeline-content .cd-date { position: absolute; width: 100%; left: 122%; top: 6px; font-size: 16px; padding: 0; }
	.cd-center .cd-timeline-content .cd-date span, .cd-center .cd-timeline-content .cd-date strong, .cd-center .cd-timeline-content .cd-date em { display: block; padding: 0; }
	.cd-center .cd-timeline-block:nth-child(even) .cd-timeline-content { float: right; }
	.cd-center .cd-timeline-block:nth-child(even) .cd-timeline-content::before { top: 24px; left: auto; right: 100%; border-color: transparent; border-right-color: white; }
	.cd-center .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more { float: right; }
	.cd-center .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date { left: auto; right: 122%; text-align: right; }
	.cd-center .cd-timeline-content.is-hidden { visibility: hidden; }
	.cd-center .cd-timeline-content.bounce-in { visibility: visible; -webkit-animation: cd-bounce-2 0.6s; -moz-animation: cd-bounce-2 0.6s; animation: cd-bounce-2 0.6s; }
	.cd-center .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in { -webkit-animation: cd-bounce-2-inverse 0.6s; -moz-animation: cd-bounce-2-inverse 0.6s; animation: cd-bounce-2-inverse 0.6s; }
}


/******** COLORS *********/

.btn-circle { display: inline-block; border-style: solid; border-width: 2px; border-radius: 0.45em; vertical-align: middle; height: 0.9em; width: 0.9em; position: relative; }

.edu   .btn-circle { border-color: #52a846; }
.pro   .btn-circle { border-color: #854348; }
.perso .btn-circle { border-color: #D1AD30; }
.asso  .btn-circle { border-color: #2185A5; }

.active.edu   .btn-circle, .cd-timeline-img.cd-type-edu   { background: #75ce66; }
.active.pro   .btn-circle, .cd-timeline-img.cd-type-pro   { background: #c03b44; }
.active.perso .btn-circle, .cd-timeline-img.cd-type-perso { background: #f0ca45; }
.active.asso  .btn-circle, .cd-timeline-img.cd-type-asso  { background: #2AB9E6; }

.color-1 .btn-circle, .cd-tags span.color-1 { border-color: #8757B5; }
.color-2 .btn-circle, .cd-tags span.color-2 { border-color: #5372BF; }
.color-3 .btn-circle, .cd-tags span.color-3 { border-color: #65B0C2; }
.color-4 .btn-circle, .cd-tags span.color-4 { border-color: #53BF5C; }
.color-5 .btn-circle, .cd-tags span.color-5 { border-color: #D8D687; }
.color-6 .btn-circle, .cd-tags span.color-6 { border-color: #E2AA2F; }
.color-7 .btn-circle, .cd-tags span.color-7 { border-color: #CC6C51; }
.color-8 .btn-circle, .cd-tags span.color-8 { border-color: #AF5067; }

.active.color-1 .btn-circle, .cd-tags span.color-1 { background: #C49EFF; }
.active.color-2 .btn-circle, .cd-tags span.color-2 { background: #93BDFF; }
.active.color-3 .btn-circle, .cd-tags span.color-3 { background: #B5E3EE; }
.active.color-4 .btn-circle, .cd-tags span.color-4 { background: #87E884; }
.active.color-5 .btn-circle, .cd-tags span.color-5 { background: #FFFD91; }
.active.color-6 .btn-circle, .cd-tags span.color-6 { background: #fdda98; }
.active.color-7 .btn-circle, .cd-tags span.color-7 { background: #FFBBA8; }
.active.color-8 .btn-circle, .cd-tags span.color-8 { background: #E895A9; }

/******** LATEX ***********/

.latex sub, .latex sup { text-transform: uppercase; line-height: 1em; position: inherit; }
.latex sub { font-size: 1em; vertical-align: -0.5ex; margin-left: -0.1667em; margin-right: -0.125em; }
.latex sup { font-size: 0.85em; vertical-align: 0.15em; margin-left: -0.36em; margin-right: -0.15em; }


/* ANIMATIONS */

@-webkit-keyframes cd-leave-img {
	0% { opacity: 1; -webkit-transform: scale(1); }
	100% { opacity: 0; -webkit-transform: scale(0.5); }
}

@-webkit-keyframes cd-leave-content {
	0% { opacity: 1; -webkit-transform: translateX(0); }
	100% { opacity: 0; -webkit-transform: translateX(100px); }
}

@-webkit-keyframes cd-enter-img {
	0% { opacity: 0; -webkit-transform: scale(0.5); }
	25% { opacity: 0; -webkit-transform: scale(0.5); }
	70% { opacity: 1; -webkit-transform: scale(1.2); }
	100% { -webkit-transform: scale(1); }
}

@-webkit-keyframes cd-enter-content {
	0% { opacity: 0; -webkit-transform: translateX(100px); }
	25% { opacity: 0; -webkit-transform: translateX(100px); }
	70% { opacity: 1; -webkit-transform: translateX(-20px); }
	100% { -webkit-transform: translateX(0); }
}

@-moz-keyframes cd-leave-img { 0% { opacity: 1; -moz-transform: scale(1); } 100% { opacity: 0; -moz-transform: scale(0.5); } }
@-moz-keyframes cd-leave-content { 0% { opacity: 1; -moz-transform: translateX(0); } 100% { opacity: 0; -moz-transform: translateX(100px); } }
@-moz-keyframes cd-enter-img { 0% { opacity: 0; -moz-transform: scale(0.5); } 25% { opacity: 0; -moz-transform: scale(0.5); } 70% { opacity: 1; -moz-transform: scale(1.2); } 100% { -moz-transform: scale(1); } }
@-moz-keyframes cd-enter-content { 0% { opacity: 0; -moz-transform: translateX(100px); } 25% { opacity: 0; -moz-transform: translateX(100px); } 70% { opacity: 1; -moz-transform: translateX(-20px); } 100% { -moz-transform: translateX(0); } }

@keyframes cd-leave-img { 0% { opacity: 1; -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); } 100% { opacity: 0; -webkit-transform: scale(0.5); -moz-transform: scale(0.5); -ms-transform: scale(0.5); -o-transform: scale(0.5); transform: scale(0.5); } }
@keyframes cd-leave-content { 0% { opacity: 1; -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); transform: translateX(0); } 100% { opacity: 0; -webkit-transform: translateX(100px); -moz-transform: translateX(100px); -ms-transform: translateX(100px); -o-transform: translateX(100px); transform: translateX(100px); } }
@keyframes cd-enter-img { 0% { opacity: 0; -webkit-transform: scale(0.5); -moz-transform: scale(0.5); -ms-transform: scale(0.5); -o-transform: scale(0.5); transform: scale(0.5); } 25% { opacity: 0; -webkit-transform: scale(0.5); -moz-transform: scale(0.5); -ms-transform: scale(0.5); -o-transform: scale(0.5); transform: scale(0.5); } 70% { opacity: 1; -webkit-transform: scale(1.2); -moz-transform: scale(1.2); -ms-transform: scale(1.2); -o-transform: scale(1.2); transform: scale(1.2); } 100% { -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); } }
@keyframes cd-enter-content { 0% { opacity: 0; -webkit-transform: translateX(100px); -moz-transform: translateX(100px); -ms-transform: translateX(100px); -o-transform: translateX(100px); transform: translateX(100px); } 25% { opacity: 0; -webkit-transform: translateX(100px); -moz-transform: translateX(100px); -ms-transform: translateX(100px); -o-transform: translateX(100px); transform: translateX(100px); } 70% { opacity: 1; -webkit-transform: translateX(-20px); -moz-transform: translateX(-20px); -ms-transform: translateX(-20px); -o-transform: translateX(-20px); transform: translateX(-20px); } 100% { -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); transform: translateX(0); } }



@-webkit-keyframes cd-bounce-1 {
	0% { opacity: 0; -webkit-transform: scale(0.5); }
	60% { opacity: 1; -webkit-transform: scale(1.2); }
	100% { -webkit-transform: scale(1); }
}
@-moz-keyframes cd-bounce-1 {
	0% { opacity: 0; -moz-transform: scale(0.5); }
	60% { opacity: 1; -moz-transform: scale(1.2); }
	100% { -moz-transform: scale(1); }
}
@keyframes cd-bounce-1 {
	0% { opacity: 0; -webkit-transform: scale(0.5); -moz-transform: scale(0.5); -ms-transform: scale(0.5); -o-transform: scale(0.5); transform: scale(0.5); }
	60% { opacity: 1; -webkit-transform: scale(1.2); -moz-transform: scale(1.2); -ms-transform: scale(1.2); -o-transform: scale(1.2); transform: scale(1.2); }
	100% { -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); }
}


@-webkit-keyframes cd-bounce-2 {
	0% { opacity: 0; -webkit-transform: translateX(-100px); }
	60% { opacity: 1; -webkit-transform: translateX(20px); }
	100% { -webkit-transform: translateX(0); }
}

@-moz-keyframes cd-bounce-2 {
	0% { opacity: 0; -moz-transform: translateX(-100px); }
	60% { opacity: 1; -moz-transform: translateX(20px); }
	100% { -moz-transform: translateX(0); }
}

@keyframes cd-bounce-2 {
	0% { opacity: 0; -webkit-transform: translateX(-100px); -moz-transform: translateX(-100px); -ms-transform: translateX(-100px); -o-transform: translateX(-100px); transform: translateX(-100px); }
	60% { opacity: 1; -webkit-transform: translateX(20px); -moz-transform: translateX(20px); -ms-transform: translateX(20px); -o-transform: translateX(20px); transform: translateX(20px); }
	100% { -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); transform: translateX(0); }
}

@-webkit-keyframes cd-bounce-2-inverse {
	0% { opacity: 0; -webkit-transform: translateX(100px); }
	60% { opacity: 1; -webkit-transform: translateX(-20px); }
	100% { -webkit-transform: translateX(0); }
}

@-moz-keyframes cd-bounce-2-inverse {
	0% { opacity: 0; -moz-transform: translateX(100px); }
	60% { opacity: 1; -moz-transform: translateX(-20px); }
	100% { -moz-transform: translateX(0); }
}

@keyframes cd-bounce-2-inverse {
	0% { opacity: 0; -webkit-transform: translateX(100px); -moz-transform: translateX(100px); -ms-transform: translateX(100px); -o-transform: translateX(100px); transform: translateX(100px); }
	60% { opacity: 1; -webkit-transform: translateX(-20px); -moz-transform: translateX(-20px); -ms-transform: translateX(-20px); -o-transform: translateX(-20px); transform: translateX(-20px); }
	100% { -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); transform: translateX(0); }
}
